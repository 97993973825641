import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import DateFilter from './DateFilter';
import SummaryCard from './SummaryCard';
import ArticleCountCard from './ArticleCountCard';
import PieChartComponent from './PieChartComponent';
import AlexandraPayout from './AlexandraPayout';

const PriceAnalytics = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState({});
  const [articleCount, setArticleCount] = useState({});
  const [totalProfit, setTotalProfit] = useState(0);

  useEffect(() => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    const startOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
    setStartDate(startOfMonth);
    setEndDate(currentDate);
  }, []);

  const handleFilter = async () => {
    try {
      const response = await axios.get('https://server-9p4hx.kinsta.app/api/jurnale/intrari-ai', {
        params: { startDate, endDate } 
      });
      setData(response.data);
      calculateSummary(response.data);
      calculateArticleCount(response.data);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  const calculateSummary = (filteredData) => {
    const summary = {
      under2000: 0,
      under6000: 0,
      under15000: 0,
      over15000: 0
    };

    let totalProfit = 0;

    filteredData.forEach(item => {
      const profit = item.pretDeVanzareRON - item.pretIntrareRON;
      totalProfit += profit;
      if (profit <= 2000) {
        summary.under2000 += profit;
      } else if (profit > 2000 && profit <= 6000) {
        summary.under6000 += profit;
      } else if (profit > 6000 && profit <= 15000) {
        summary.under15000 += profit;
      } else if (profit > 15000) {
        summary.over15000 += profit;
      }
    });

    setSummary(summary);
    setTotalProfit(totalProfit);
  };

  const calculateArticleCount = (filteredData) => {
    const count = {
      under2000: 0,
      under6000: 0,
      under15000: 0,
      over15000: 0
    };

    filteredData.forEach(item => {
      const profit = item.pretDeVanzareRON - item.pretIntrareRON;
      if (profit <= 2000) {
        count.under2000 += 1;
      } else if (profit > 2000 && profit <= 6000) {
        count.under6000 += 1;
      } else if (profit > 6000 && profit <= 15000) {
        count.under15000 += 1;
      } else if (profit > 15000) {
        count.over15000 += 1;
      }
    });

    setArticleCount(count);
  };

  const maxValues = {
    under2000: 25262,
    under6000: 80205,
    under15000: 66000,
    over15000: 5000,
  };

  const maxArticles = {
    under2000: 84,
    under6000: 86,
    under15000: 28,
    over15000: 2,
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Data Filter</Typography> 
      <DateFilter
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleFilter={handleFilter}
      />
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={12} md={6}>
          <SummaryCard
            title="Under 2000 RON"
            value={summary.under2000}
            maxValue={maxValues.under2000}
          />
          <SummaryCard
            title="Under 6000 RON"
            value={summary.under6000}
            maxValue={maxValues.under6000}
          />
          <SummaryCard
            title="Under 15000 RON"
            value={summary.under15000}
            maxValue={maxValues.under15000}
          />
          <SummaryCard
            title="Over 15000 RON"
            value={summary.over15000}
            maxValue={maxValues.over15000}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ArticleCountCard
            title="Under 2000 RON"
            count={articleCount.under2000}
            maxCount={maxArticles.under2000}
          />
          <ArticleCountCard
            title="Under 6000 RON"
            count={articleCount.under6000}
            maxCount={maxArticles.under6000}
          />
          <ArticleCountCard
            title="Under 15000 RON"
            count={articleCount.under15000}
            maxCount={maxArticles.under15000}
          />
          <ArticleCountCard
            title="Over 15000 RON"
            count={articleCount.over15000}
            maxCount={maxArticles.over15000}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PieChartComponent profit={totalProfit} />
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Am adăugat componenta AlexandraPayout aici */}
          {data.length > 0 && <AlexandraPayout data={data} />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default PriceAnalytics;
