import React, { useState, useEffect } from 'react';
import { Box, Card, TextField, Button, MenuItem, Grid, FormControlLabel, Checkbox, Typography, TablePagination } from '@mui/material';
import axios from 'axios';
import Results from './Results'; // Import the Results component
import calculateCost from './calculateCost'; // Import the calculateCost function

const EXCHANGE_RATE_API_URL = 'https://api.exchangerate-api.com/v4/latest/USD';

function InputForm({ formData, onInputChange, onCalculate, onReset, data, setResults, manualAvgPrice }) {
  const [categories] = useState(['Genti', 'Incaltaminte', 'Accesorii', 'Bijuterii', 'Ceasuri']);
  const [brands, setBrands] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [selectedAccessories, setSelectedAccessories] = useState([
    'cutie - 150 RON', 'dusbag - 40 RON', 'factura - 100 lei', 'certificat - 500 lei', 'poze', 'marketing', 'autenticitate - 100 lei'
  ]); // All checked by default
  const [isPriceModified, setIsPriceModified] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [exchangeRate, setExchangeRate] = useState(1);
  const [avgPrice, setAvgPrice] = useState(0);
  const [localManualAvgPrice, setLocalManualAvgPrice] = useState(manualAvgPrice || '');
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [results, setResultsState] = useState(null); // State to store results

  useEffect(() => {
    const uniqueBrands = [...new Set(data.map(item => item.Brand))];
    setBrands(uniqueBrands);
  }, [data]);

  useEffect(() => {
    axios.get(EXCHANGE_RATE_API_URL)
      .then(response => {
        const rate = response.data.rates.RON;
        setExchangeRate(rate);
      })
      .catch(error => {
        console.error('Error fetching the exchange rate:', error);
      });
  }, []);

  useEffect(() => {
    if (manualAvgPrice) {
      setLocalManualAvgPrice(manualAvgPrice);
      handleRecalculate(); // Recalculăm automat la montarea componentei
    }
  }, [manualAvgPrice]);

  const handleBrandChange = (e) => {
    onInputChange(e);
    setFilteredModels([]); // Clear filtered models when brand changes
    setConditions([]);
    setSelectedAccessories([
      'cutie - 150 RON', 'dusbag - 40 RON', 'factura - 100 lei', 'certificat - 500 lei', 'poze', 'marketing', 'autenticitate - 100 lei'
    ]); // Reset accessories
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = () => {
    const searchTerms = searchTerm.toLowerCase().split(' ');

    const filteredByModel = data.filter(item =>
      item.Brand === formData.brand &&
      searchTerms.every(term => item.Model && item.Model.toLowerCase().includes(term))
    );

    const filteredByNameModel = data.filter(item =>
      item.Brand === formData.brand &&
      searchTerms.every(term => item.Namemodel && item.Namemodel.toLowerCase().includes(term))
    );

    const combinedFiltered = [...filteredByModel, ...filteredByNameModel];

    // Eliminați duplicatele pe baza unui identificator unic (presupunem că "id" este unic)
    const uniqueFiltered = Array.from(new Set(combinedFiltered.map(item => item.id)))
                                .map(id => combinedFiltered.find(item => item.id === id));

    setFilteredModels(uniqueFiltered);
  };

  const handleModelSelect = (model) => {
    const newSelectedModels = selectedModels.some(m => m.id === model.id)
      ? selectedModels.filter(m => m.id !== model.id)
      : [...selectedModels, model];
    setSelectedModels(newSelectedModels);

    if (newSelectedModels.length > 0) {
      const filteredConditions = data
        .filter(item => newSelectedModels.some(m => m.id === item.id))
        .map(item => item.Conditio);
      const uniqueConditions = [...new Set(filteredConditions)];
      setConditions(uniqueConditions);
    } else {
      setConditions([]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleConditionChange = (e) => {
    const condition = e.target.value;
    onInputChange({ target: { name: 'conditie', value: condition } });
  };

  const handleAccessoryChange = (e) => {
    const { name, checked } = e.target;
    setSelectedAccessories((prev) => checked ? [...prev, name] : prev.filter((accessory) => accessory !== name));
  };

  useEffect(() => {
    onInputChange({ target: { name: 'accessories', value: selectedAccessories } });
  }, [selectedAccessories, onInputChange]);

  const handleManualAveragePriceChange = (e) => {
    setLocalManualAvgPrice(e.target.value); // Updated to use local state variable
    setIsPriceModified(true);
  };

  const handleCalculate = () => {
    if (selectedModels.length > 0 && formData.conditie && formData.categorie) {
      const filteredPrices = data
        .filter(item => selectedModels.some(m => m.id === item.id) && item.Conditio === formData.conditie)
        .map(item => {
          const price = item.Price_sale !== 'N/A' && item.Price_sale !== null ? item.Price_sale : item.Price;
          return parseFloat(price) * exchangeRate;
        });

      if (filteredPrices.length > 0) {
        const averagePrice = filteredPrices.reduce((sum, price) => sum + price, 0) / filteredPrices.length;
        setAvgPrice(averagePrice);
        setLocalManualAvgPrice(averagePrice.toFixed(0)); // Updated to use local state variable
        setMinPrice(Math.min(...filteredPrices));
        setMaxPrice(Math.max(...filteredPrices));
        const costResults = calculateCost(averagePrice, selectedAccessories, formData.categorie);
        setResultsState({ ...costResults, avgPrice: averagePrice.toFixed(2), filteredData: filteredModels });
      } else {
        setAvgPrice(0);
        setLocalManualAvgPrice(''); // Updated to use local state variable
        setMinPrice(0);
        setMaxPrice(0);
        setResultsState(null);
      }
    } else {
      setAvgPrice(0);
      setLocalManualAvgPrice(''); // Updated to use local state variable
      setMinPrice(0);
      setMaxPrice(0);
      setResultsState(null);
    }
  };

  const handleRecalculate = () => {
    const manualPrice = parseFloat(localManualAvgPrice); // Updated to use local state variable
    if (!isNaN(manualPrice)) {
      const costResults = calculateCost(manualPrice, selectedAccessories, formData.categorie);
      setResultsState({ ...costResults, avgPrice: manualPrice.toFixed(2), filteredData: filteredModels });
    }
    setIsPriceModified(false);
  };

  const accessoriesList = [
    'cutie - 150 RON',
    'dusbag - 40 RON',
    'factura - 100 lei',
    'certificat - 500 lei',
    'poze',
    'marketing',
    'autenticitate - 100 lei'
  ];

  return (
    <Box sx={{ backgroundColor: '#fdfdfd', p: 3, maxWidth: '100%', overflowX: 'hidden' }}>
      <Card variant="outlined" sx={{ p: 2, mb: 3 }}>
        <Box component="form" noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                select
                fullWidth
                label="Brand"
                name="brand"
                value={formData.brand}
                onChange={handleBrandChange}
                margin="normal"
                variant="outlined"
              >
                {(brands || []).map((brand) => (
                  <MenuItem key={brand} value={brand}>
                    {brand}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                label="Search Models"
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSearchSubmit();
                  }
                }}
                margin="normal"
                variant="outlined"
                disabled={!formData.brand}
              />
            </Grid>
          </Grid>

          {filteredModels.length > 0 && (
            <>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {filteredModels.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((modelObj, index) => (
                  <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                    <Card>
                      <Box sx={{ p: 2, textAlign: 'center' }}>
                        <img src={modelObj.Image} alt={modelObj.model} style={{ width: '100%', height: 'auto' }} />
                        <Typography>{modelObj.Model !== 'N/A' ? modelObj.Model : modelObj.Namemodel}</Typography>
                        <Typography>{(modelObj.Price_sale !== 'N/A' && modelObj.Price_sale !== null) ? (modelObj.Price_sale * exchangeRate).toFixed(2) : (modelObj.Price * exchangeRate).toFixed(2)} RON</Typography>
                        <Typography>{modelObj.Conditio}</Typography>
                        <FormControlLabel
                          control={<Checkbox checked={selectedModels.some(m => m.id === modelObj.id)} onChange={() => handleModelSelect(modelObj)} />}
                          label="Select"
                        />
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredModels.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} md={4}>
                  <TextField
                    select
                    fullWidth
                    label="Conditie"
                    name="conditie"
                    value={formData.conditie}
                    onChange={handleConditionChange}
                    margin="normal"
                    variant="outlined"
                    disabled={selectedModels.length === 0}
                  >
                    {conditions.map((condition) => (
                      <MenuItem key={condition} value={condition}>
                        {condition}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    select
                    fullWidth
                    label="Categorie"
                    name="categorie"
                    value={formData.categorie}
                    onChange={onInputChange}
                    margin="normal"
                    variant="outlined"
                  >
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </>
          )}

          <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Card
              variant="outlined"
              sx={{
                p: 2,
                width: '100%',
                height: '300px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 2,
                backgroundColor: '#004d40',
                borderRadius: '16px',
                color: 'white'
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Typography sx={{ color: 'white', fontSize: '1.2rem' }}>Pretul Maxim de Vanzare</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography sx={{ color: 'white', fontSize: '1.2rem' }}>RON</Typography>
                  <TextField
                    fullWidth
                    value={localManualAvgPrice} // Updated to use local state variable
                    onChange={handleManualAveragePriceChange}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      disableUnderline: true,
                      style: { fontSize: '3rem', color: 'white', backgroundColor: 'transparent', border: 'none' }
                    }}
                    sx={{ backgroundColor: 'transparent', border: 'none', '& .MuiInputBase-input': { textAlign: 'left' } }}
                  />
                </Box>
                <Typography sx={{ color: 'white', fontSize: '1.2rem' }}>Pret Min: {(minPrice).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} RON</Typography>
                <Typography sx={{ color: 'white', fontSize: '1.2rem' }}>Pret Max: {(maxPrice).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} RON</Typography>
              </Box>
            </Card>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }} alignItems="center">
            {accessoriesList.map((accessory) => (
              <Grid item key={accessory}>
                <FormControlLabel
                  control={<Checkbox checked={selectedAccessories.includes(accessory)} onChange={handleAccessoryChange} name={accessory} />}
                  label={accessory}
                />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleCalculate} sx={{ mr: 2 }}>
                Calculate
              </Button>
              <Button variant="outlined" color="secondary" onClick={onReset}>
                Reset
              </Button>
              {isPriceModified && (
                <Button variant="contained" color="success" onClick={handleRecalculate} sx={{ ml: 2 }}>
                  Recalculate
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Card>

      {results && (
        <Results results={results} category={formData.categorie} avgPrice={localManualAvgPrice} />
      )}
    </Box>
  );
}

export default InputForm;
