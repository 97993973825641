import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Chip, Select, MenuItem, FormControl, InputLabel, Button, Box, TextField, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useProductContext } from './ProductContextAnalysisStoc';
import WarningIcon from '@mui/icons-material/Warning';

const statusColors = {
  available: 'green',
  out_of_stock: 'red',
  discontinued: 'grey'
};

const rotationFilters = [
  { label: 'More than 90 days', value: 90 },
  { label: 'More than 120 days', value: 120 },
  { label: 'More than 150 days', value: 150 },
  { label: 'More than 180 days', value: 180 }
];

const ProductTable = ({ onProductClick, onCreateProductClick, onEditProductClick }) => {
  const {
    products,
    setProducts,
    filteredProducts,
    setFilteredProducts,
    statuses,
    setStatuses,
    selectedStatus,
    setSelectedStatus,
    rotationFilter,
    setRotationFilter,
    skuSearch,
    setSkuSearch,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalProducts,
    setTotalProducts
  } = useProductContext();

  const [loading, setLoading] = useState(false);
  const [updatedToday, setUpdatedToday] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, [page, rowsPerPage, selectedStatus, rotationFilter, skuSearch]);

  useEffect(() => {
    const uniqueStatuses = [...new Set(products.map(product => product.status))];
    setStatuses(uniqueStatuses);
  }, [products]);

  useEffect(() => {
    checkForUpdates();
  }, []);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://server-9p4hx.kinsta.app/api/masterdata/products-pagination', {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          status: selectedStatus || undefined,
          rotation: rotationFilter || undefined,
          sku: skuSearch || undefined
        }
      });
      const sortedProducts = response.data.products.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
      setProducts(sortedProducts);
      setTotalProducts(response.data.total);
      setFilteredProducts(sortedProducts);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const checkForUpdates = async () => {
    try {
      const response = await axios.get('https://server-9p4hx.kinsta.app/api/masterdata/check-updates'); // API endpoint to check updates
      setUpdatedToday(response.data.updatedToday);
    } catch (error) {
      console.error('Error checking for updates:', error);
    }
  };

  const handleSendReport = async () => {
    try {
      await axios.post('https://server-9p4hx.kinsta.app/api/masterdata/send-report');
      alert('Report sent to Slack successfully!');
    } catch (error) {
      console.error('Error sending report:', error);
      alert('Failed to send report.');
    }
  };

  const handleSyncProducts = async () => {
    setLoading(true);
    try {
      await axios.get('https://server-9p4hx.kinsta.app/sync-products');
      alert('Products synchronized successfully!');
      fetchProducts();  // Refresh the product list after synchronization
    } catch (error) {
      console.error('Error synchronizing products:', error);
      alert('Failed to synchronize products.');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusColor = (status) => {
    return statusColors[status] || 'blue';
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setPage(0);  // Reset the page number when filter changes
  };

  const handleRotationFilterChange = (event) => {
    setRotationFilter(event.target.value);
    setPage(0);  // Reset the page number when filter changes
  };

  const handleSkuSearchChange = (event) => {
    setSkuSearch(event.target.value);
    setPage(0);  // Reset the page number when search changes
  };

  const resetFilter = () => {
    setSelectedStatus('publish');
    setRotationFilter('');
    setSkuSearch('');
    setPage(0);
    fetchProducts();
  };

  return (
    <Paper>
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2, justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 150, mr: 2 }}>
            <InputLabel>Status</InputLabel>
            <Select value={selectedStatus} onChange={handleStatusChange} label="Status">
              <MenuItem value=""><em>All</em></MenuItem>
              {statuses.map(status => (
                <MenuItem key={status} value={status}>{status}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" size="small" sx={{ minWidth: 150, mr: 2 }}>
            <InputLabel>Rotation</InputLabel>
            <Select value={rotationFilter} onChange={handleRotationFilterChange} label="Rotation">
              <MenuItem value=""><em>All</em></MenuItem>
              {rotationFilters.map(filter => (
                <MenuItem key={filter.value} value={filter.value}>{filter.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label="Search SKU"
            value={skuSearch}
            onChange={handleSkuSearchChange}
            sx={{ mr: 2 }}
          />
          <Button variant="contained" color="primary" onClick={resetFilter}>
            Reset
          </Button>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {updatedToday && (
            <Button
              variant="contained"
              color="warning"
              onClick={handleSendReport}
              sx={{ ml: 2 }}
              startIcon={<WarningIcon />}
            >
              Send Report to Slack
            </Button>
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSyncProducts}
            sx={{ ml: 2 }}
          >
            Sync Products
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onCreateProductClick}
            sx={{ ml: 2 }}
          >
            Creează Produs
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Rotation</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProducts.map((product) => (
                <TableRow key={product.id} onClick={() => onProductClick(product)}>
                  <TableCell>
                    <img src={product.images[0]?.src} alt={product.name} style={{ width: '100px' }} />
                  </TableCell>
                  <TableCell>{product.attributes.find(attr => attr.name === 'Designeri')?.options[0]}</TableCell>
                  <TableCell dangerouslySetInnerHTML={{ __html: product.name }} />
                  <TableCell>
                    <Chip
                      label={product.status}
                      style={{ backgroundColor: getStatusColor(product.status), color: 'white' }}
                    />
                  </TableCell>
                  <TableCell>{product.regularPrice}</TableCell>
                  <TableCell>{Math.floor((new Date() - new Date(product.dateCreated)) / (1000 * 60 * 60 * 24))} days</TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => onEditProductClick(product)}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        rowsPerPageOptions={[20, 40, 80]}
        component="div"
        count={totalProducts}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ProductTable;
