import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, TextField, Button, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import SearchIcon from '@mui/icons-material/Search';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import axios from 'axios';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const TaskTable = ({ onTaskSelect, projectKey, status, reload }) => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [customFieldOptions, setCustomFieldOptions] = useState({});
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [priceOption, setPriceOption] = useState('');
    const [currentPrice, setCurrentPrice] = useState('');
    const [newPrice, setNewPrice] = useState('');
    const [hasFetched, setHasFetched] = useState(false); // Flag pentru a preveni bucla infinită

    useEffect(() => {
        let cancelToken = axios.CancelToken.source();

        const fetchTasks = async () => {
            try {
                if (!loading) return;

                const response = await axios.get(
                    `https://server-9p4hx.kinsta.app/api/tasks/jira-tasks/${projectKey}/${status}`,
                    { cancelToken: cancelToken.token }
                );

                if (status === 'TRANZIT') {
                    await fetchCustomFieldAndAwbStatus(response.data);
                } else if (status === 'AUTENTIFICARE') {
                    await fetchAuthenticationTasks(response.data);
                } else {
                    await fetchCustomField(response.data);
                }

                setLoading(false);

            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.error('There was an error fetching the tasks!', error); 
                }
            }
        };

        const fetchCustomFieldOptions = async () => {
            try {
                const response = await axios.get(
                    'https://server-9p4hx.kinsta.app/api/tasks/jira-custom-field-options/customfield_10185'
                );

                setCustomFieldOptions((prevOptions) => ({
                    ...prevOptions,
                    customfield_10185: response.data.reduce((acc, option) => {
                        acc[option.value] = option.label;
                        return acc;
                    }, {}),
                }));

            } catch (error) {
                console.error('There was an error fetching custom field options!', error);
            }
        };

        // Fetch tasks și customFieldOptions de fiecare dată când se schimbă projectKey
        fetchTasks();
        fetchCustomFieldOptions();

        return () => {
            cancelToken.cancel('Component unmounted or endpoint changed');
        };
    }, [projectKey, status, reload]); // Dependință pe projectKey și status

    useEffect(() => {
        setFilteredTasks(
            tasks.filter(task => task.summary.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }, [searchTerm, tasks]);

    const getRenegotiationQcDetails = async (issueId) => {
        try {
            const response = await axios.get(
                `https://server-9p4hx.kinsta.app/api/qc/renegotiation-qc/${issueId}`
            );
            const { priceOption, newPrice, currentPrice } = response.data;

            setTasks(prevTasks => 
                prevTasks.map(task => 
                    task.issue_id === issueId 
                    ? { ...task, priceOption, newPrice, currentPrice } 
                    : task
                )
            );

        } catch (error) {
            console.error('Error fetching task details:', error);
        }
    };

    // Se asigură că getRenegotiationQcDetails nu e apelat în buclă infinită
    useEffect(() => {
        if (tasks.length > 0) {
            tasks.forEach(task => {
                if (!task.hasRenegotiationQcDetailsFetched) { // Verifică dacă detaliile au fost deja încărcate
                    getRenegotiationQcDetails(task.issue_id);
                    task.hasRenegotiationQcDetailsFetched = true; // Setăm flag pentru a evita re-fetch
                }
            });
        }
    }, [tasks]);

    const fetchCustomField = async (tasks) => {
        const updatedTasks = await Promise.all(tasks.map(async (task) => {
            try {
                const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${task.issue_id}`);
                const contractValid = response.data.attachments.some(att => /^lp00[1-9]|lp01[0-8]/.test(att.filename) && att.filename.endsWith('.pdf'));
                const awbValid = response.data.attachments.some(att => 
                    att.filename.toLowerCase().startsWith('awb_') && 
                    att.filename.toLowerCase().endsWith('.pdf')
                );
                const ghidValid = response.data.attachments.some(att => att.filename.includes('Cerere_oferta') && att.filename.endsWith('.pdf'));
                return { 
                    ...task, 
                    customfield_10185: response.data.customfield_10185,
                    contractValid,
                    awbValid,
                    ghidValid
                };
            } catch (error) {
                console.error('Error fetching customfield_10185:', error);
                return task;
            }
        }));
        setTasks(updatedTasks);
        setLoading(false);
    };

    const fetchCustomFieldAndAwbStatus = async (tasks) => {
        const updatedTasks = await Promise.all(tasks.map(async (task) => {
            try {
                const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${task.issue_id}`);
                const awbNumber = response.data.customfield_10163;
                let awbStatusGls = 'Unknown';

                if (awbNumber) {
                    awbStatusGls = await fetchAwbStatus(awbNumber);
                }

                const contractValid = response.data.attachments.some(att => /^lp00[1-9]|lp01[0-8]/.test(att.filename) && att.filename.endsWith('.pdf'));
                return { 
                    ...task, 
                    awbStatusGls,
                    contractValid,
                    awbNumber,
                };
            } catch (error) {
                console.error('Error fetching AWB status:', error);
                return task;
            }
        }));
        setTasks(updatedTasks);
        setLoading(false);
    };

    const fetchAwbStatus = async (awbNumber) => {
        try {
            const response = await axios.post('https://server-9p4hx.kinsta.app/api/tasks/get-awb-status', { awbNumber });

            if (response.data && response.data.statusDescription) {
                return response.data.statusDescription;
            } else {
                return 'Status not found';
            }
        } catch (error) {
            console.error('Error fetching AWB status from server:', error);
            return 'Error fetching status';
        }
    };

    // Adăugăm funcțiile corectate care lipseau

    const fetchQCValid = async (task) => {
        try {
            const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${task.issue_id}`);
            const hasQualityCheck = response.data.attachments.some(att => att.filename.includes('QualityCheck_'));

            const prodResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${task.issue_id}`);
            const summary = prodResponse.data.summary;
            const prodStatusResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/prod-status-qcvalid/${summary}`);

            const { condition, description, selectedTags, priceOption, newPrice, currentPrice } = prodStatusResponse.data;

            let qcStatus;
            
            if (hasQualityCheck && condition && description && selectedTags.length > 0) {
                qcStatus = 'complete';
            } else if (hasQualityCheck || condition || description || selectedTags.length > 0) {
                qcStatus = 'partial';
            } else {
                qcStatus = 'not_available';
            }

            setPriceOption(priceOption);
            setNewPrice(newPrice);
            setCurrentPrice(currentPrice);

            return qcStatus;
        } catch (error) {
            console.error(`Error fetching QC validation for task ${task.issue_id}:`, error);
            return 'not_available';
        }
    };

    const fetchCiornaValid = async (task) => {
        try {
            const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${task.issue_id}`);
            let ciornaValid = response.data.attachments.some(att => att.filename.includes('Ciorna_'));

            const customFieldResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/leb-customfield/${task.issue_id}`);
            const ciornaPlatforma = customFieldResponse.data.customFieldValue || '';

            if (ciornaPlatforma) {
                const wooCommerceResult = await fetchWooCommerceSKU(ciornaPlatforma);
                ciornaValid = wooCommerceResult || ciornaValid;
            }

            return ciornaValid;
        } catch (error) {
            console.error(`Error fetching Ciorna validation for task ${task.issue_id}:`, error);
            return false;
        }
    };

    const fetchRespinsValid = async (task) => {
        try {
            const response = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/jira-task/${task.issue_id}`);
            const customFieldValueResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/customfield-value-qcstatus/${task.issue_id}`);
            const customFieldValue = customFieldValueResponse.data.customFieldValue;

            let respinsValid = "not available";

            const hasRelevantAttachment = response.data.attachments.some(att => 
                att.filename.includes('_Nota_Retur_') || 
                att.filename.includes('_Nota_fk_')
            );

            const hasRespinsOrFake = customFieldValue && 
                (customFieldValue.includes('Respins') || 
                customFieldValue.includes('Fake'));

            if (hasRelevantAttachment) {
                respinsValid = 'green';
            } else if (hasRespinsOrFake) {
                respinsValid = 'orange';
            }

            return respinsValid;
        } catch (error) {
            console.error(`Error fetching Respins validation for task ${task.issue_id}:`, error);
            return "not available";
        }
    };

    const fetchProdStatus = async (task) => {
        try {
            const prodResponse = await axios.get(`https://server-9p4hx.kinsta.app/api/tasks/prod-status-studiovalid/${task.summary}`);
            const prodStatus = prodResponse.data.status;
            const isPreCheck = prodStatus === '11_PRE-CHECK';
            const fisaValidInPROD = ['CHECK', 'CIORNA', 'ONLINE'].includes(prodStatus);
            const studioValid = prodResponse.data.studioValid;

            return { fisaValid: fisaValidInPROD, studioValid, isPreCheck };
        } catch (error) {
            console.error(`Error fetching PROD status for task ${task.issue_id}:`, error);
            return { fisaValid: false, studioValid: false, isPreCheck: false };
        }
    };

    const fetchAuthenticationTasks = async (tasks) => {
        const updatedTasks = await Promise.all(tasks.map(async (task, index) => {
            const [qcValid, ciornaValid, respinsValid, prodStatus] = await Promise.all([
                fetchQCValid(task),
                fetchCiornaValid(task),
                fetchRespinsValid(task),
                fetchProdStatus(task)
            ]);

            return {
                ...task,
                qcValid,
                ciornaValid,
                respinsValid,
                fisaValid: prodStatus.fisaValid,
                studioValid: prodStatus.studioValid,
                isPreCheck: prodStatus.isPreCheck
            };
        }));

        setTasks(updatedTasks);
        setLoading(false);
    };

    const fetchWooCommerceSKU = async (sku) => {
        if (sku) {
            const consumerKey = 'ck_a941824be557253d3765a1f6242e6d6a590da999';
            const consumerSecret = 'cs_6b0d98e4c442e707be9888cafff1fac17cdbf259';
            const wooUrl = `https://luxuraelite.ro/wp-json/wc/v3/products?sku=${sku}&consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`;

            try {
                const wooResponse = await axios.get(wooUrl);
                if (wooResponse.data.length > 0) {
                    return true;
                }
            } catch (error) {
                console.error('Error fetching SKU from WooCommerce:', error);
            }
        }
        return false;
    };

    const handleRowClick = (task) => {
        onTaskSelect(task.issue_id);
    };

    const getChipColor = (status) => {
        switch (status) {
            case 'Nepreluat':
                return 'default';
            case 'Preluat':
                return 'warning';
            case 'Acceptat':
                return 'success';
            case 'Anulat':
                return 'error';
            default:
                return 'default';
        }
    };

    const handleExportPDF = async () => {
        try {
            const tasksToExport = tasks.filter(task => 
                !task.qcValid || task.qcValid !== 'complete'
            );

            const response = await axios.post('https://server-9p4hx.kinsta.app/api/tasks/export-pdf-qc', {
                tasks: tasksToExport,
            }, { responseType: 'blob' });

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Tasks_QC_Invalid.pdf';
            link.click();
        } catch (error) {
            console.error('Error exporting to PDF:', error);
        }
    };

    return (
        <TableContainer component={Paper} sx={{ width: '100%' }}>
            <div style={{ padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search Summary..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <IconButton position="start">
                                <SearchIcon />
                            </IconButton>
                        ),
                    }}
                />
                {status === 'AUTENTIFICARE' && (
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveAltIcon />}
                        onClick={handleExportPDF}
                    >
                        Export QC PDF
                    </Button>
                )}
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Issue ID</TableCell>
                            <TableCell>Summary</TableCell>
                            {status === 'TRANZIT' ? (
                                <>
                                    <TableCell>Status AWB GLS</TableCell>
                                    <TableCell>Contract</TableCell>
                                </>
                            ) : status === 'AUTENTIFICARE' ? (
                                <>
                                    <TableCell>QC</TableCell>
                                    <TableCell>Fisa</TableCell>
                                    <TableCell>Studio</TableCell>
                                    <TableCell>Ciorna</TableCell>
                                    <TableCell>Respins</TableCell>
                                </>
                            ) : (
                                <>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Created Date</TableCell>
                                    <TableCell>Contactat</TableCell>
                                </>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredTasks.map((task) => (
                            <TableRow
                                key={task.issue_id}
                                hover
                                onClick={() => handleRowClick(task)}
                                sx={{ cursor: 'pointer', '&:hover': { boxShadow: 3 } }}
                            >
                                <TableCell>
                                    {task.issue_id}
                                {task.priceOption === 'Propunere pret nou' && (
                                    <Chip 
                                        label="Renegociere"
                                        color="warning"
                                        sx={{ marginLeft: 1, fontSize: '0.75rem', height: '20px', padding: '0 6px' }}
                                    />
                                )}
                                </TableCell>
                                <TableCell>{task.summary}</TableCell>
                                {status === 'TRANZIT' ? (
                                    <>
                                        <TableCell>{task.awbStatusGls}</TableCell>
                                        <TableCell>
                                            {task.contractValid ? <CheckCircleIcon style={{ color: 'green' }} /> : 'Not available'}
                                        </TableCell>
                                    </>
                                ) : status === 'AUTENTIFICARE' ? (
                                    <>
                                        <TableCell>
                                            {task.qcValid === 'complete' ? (
                                                <CheckCircleIcon style={{ color: 'green' }} />
                                            ) : task.qcValid === 'partial' ? (
                                                <WarningIcon style={{ color: 'orange' }} />
                                            ) : 'Not available'}
                                        </TableCell>
                                        <TableCell>
                                            {task.isPreCheck ? (
                                                <HourglassEmptyIcon style={{ color: 'blue' }} />
                                            ) : task.fisaValid ? (
                                                <CheckCircleIcon style={{ color: 'green' }} />
                                            ) : 'Not available'}
                                        </TableCell>
                                        <TableCell>
                                            {task.studioValid ? <CheckCircleIcon style={{ color: 'green' }} /> : 'Not available'}
                                        </TableCell>
                                        <TableCell>
                                            {task.ciornaValid ? <CheckCircleIcon style={{ color: 'green' }} /> : 'Not available'}
                                        </TableCell>
                                        <TableCell>
                                            {task.respinsValid === 'green' ? (
                                                <CheckCircleIcon style={{ color: 'green' }} />
                                            ) : task.respinsValid === 'orange' ? (
                                                <WarningIcon style={{ color: 'orange' }} />
                                            ) : 'Not available'}
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell>{task.status}</TableCell>
                                        <TableCell>{new Date(task.created_date).toLocaleDateString()}</TableCell>
                                        <TableCell>
                                            <Chip
                                                label={customFieldOptions.customfield_10185 ? customFieldOptions.customfield_10185[task.customfield_10185] : task.customfield_10185}
                                                color={getChipColor(customFieldOptions.customfield_10185 ? customFieldOptions.customfield_10185[task.customfield_10185] : task.customfield_10185)}
                                            />
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </TableContainer>
    );
};

export default TaskTable;
