import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, TextField, Button, Checkbox, FormControlLabel, Typography, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import axios from 'axios';

const AwbAccordion = ({ issueId, contactName, contactPhone, contactEmail }) => {
    const [schedule, setSchedule] = useState(false);
    const [scheduleDate, setScheduleDate] = useState('');
    const [pickupDate, setPickupDate] = useState('');
    const [serverTime, setServerTime] = useState(null);

    const [awbData, setAwbData] = useState({
        street: '',
        number: '',
        postalCode: '', 
        city: '',
        county: '',
        country: 'Romania',
        observations: '',
        contactName: contactName || '',
        contactPhone: contactPhone || '',
        contactEmail: contactEmail || ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAwbData({
            ...awbData,
            [name]: value
        });
    };

    const fetchServerTime = async () => {
        try {
            const response = await axios.get('https://server-9p4hx.kinsta.app/api/tasks/server-time');
            setServerTime(new Date(response.data.serverTime));
        } catch (error) {
            console.error('Error fetching server time:', error);
        }
    };

    useEffect(() => {
        fetchServerTime();
    }, []);

const handleDateChange = (e) => {
    if (!serverTime) return;

    const { value } = e.target;
    let selectedDate = new Date(value);
    const today = new Date(serverTime);

    // Ajustează ora serverului la fusul orar al României (UTC+3 vara sau UTC+2 iarna)
    const romaniaTime = new Date(today.getTime());
    const romaniaOffset = romaniaTime.getTimezoneOffset() * 60000; // compensare pentru diferența de fus orar
    romaniaTime.setTime(romaniaTime.getTime() + romaniaOffset + 3 * 60 * 60 * 1000); // ajustare la ora locală România

    // Verificarea corectă a orei pentru selecția datei de ridicare
    if (romaniaTime.getHours() < 16) {
        const tomorrow = new Date(romaniaTime);
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0); // Normalizează ora la începutul zilei

        if (selectedDate.getTime() === tomorrow.getTime()) {
            if (tomorrow.getDay() === 6 || tomorrow.getDay() === 0) {
                alert("Nu puteți selecta o dată de ridicare în weekend. Selectați următoarea zi lucrătoare.");
                return;
            }
        } else if (selectedDate < tomorrow) {
            alert("Nu puteți selecta o dată de ridicare mai devreme de ziua de mâine.");
            return;
        }
    } else {
        const dayAfterTomorrow = new Date(romaniaTime);
        dayAfterTomorrow.setDate(romaniaTime.getDate() + 2);
        dayAfterTomorrow.setHours(0, 0, 0, 0); // Normalizează ora la începutul zilei

        if (selectedDate.getTime() < dayAfterTomorrow.getTime()) {
            alert("Este prea târziu pentru a selecta o dată de ridicare mâine. Selectați o dată de ridicare peste două zile.");
            return;
        }

        if (selectedDate.getDay() === 6 || selectedDate.getDay() === 0) {
            alert("Nu puteți selecta o dată de ridicare în weekend. Selectați următoarea zi lucrătoare.");
            return;
        }
    }

    setPickupDate(selectedDate.toISOString().split('T')[0]);
};


    useEffect(() => {
        const existingScript = document.getElementById('google-maps');
        if (!existingScript) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDaD41eOhElOUvJy51cf1-zJ_kWWPdrsd0&libraries=places`;
            script.id = 'google-maps';
            document.body.appendChild(script);

            script.onload = () => {
                console.log('Google Maps API script loaded.');
            };

            script.onerror = () => {
                console.error('Error loading Google Maps API script.');
            };
        } else {
            console.log('Google Maps API script already loaded.');
        }
    }, []);

    const createAwb = async () => {
        try {
            const requestBody = {
                ...awbData,
                contactName: contactName || awbData.contactName,
                contactPhone: contactPhone || awbData.contactPhone,
                contactEmail: contactEmail || awbData.contactEmail,
                issueId,
                pickupDate
            };

            if (schedule) {
                requestBody.scheduleDate = scheduleDate;
            }

            const response = await fetch('https://server-9p4hx.kinsta.app/api/tasks/create-awb', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            if (response.ok) {
                const responseBody = await response.json();
                if (schedule) {
                    console.log('AWB scheduled successfully.');
                } else {
                    const awbNumber = responseBody.awbNumber;
                    const awbPdfBase64 = responseBody.awbPdf;

                    const [firstName, ...lastName] = (contactName || awbData.contactName).split(' ');
                    const lastNameStr = lastName.join(' ');
                    const fileName = `awb_${firstName}_${lastNameStr}_${awbNumber}.pdf`;

                    const binary = atob(awbPdfBase64.replace(/\s/g, ''));
                    const array = [];
                    for (let i = 0; i < binary.length; i++) {
                        array.push(binary.charCodeAt(i));
                    }
                    const blob = new Blob([new Uint8Array(array)], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);

                    console.log('AWB generated and uploaded successfully.');
                }
            } else {
                console.error('Failed to generate or schedule AWB');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const { ready, value, suggestions: { status, data }, setValue, clearSuggestions } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: 'ro' }, 
        },
        debounce: 300,
    });

    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();

        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            console.log("📍 Coordinates: ", { lat, lng });

            const addressComponents = results[0].address_components;

            const getAddressComponent = (types) => {
                const component = addressComponents.find((comp) =>
                    types.every((type) => comp.types.includes(type))
                );
                return component ? component.long_name : '';
            };

            setAwbData({
                ...awbData,
                street: getAddressComponent(['route']),
                number: getAddressComponent(['street_number']),
                postalCode: getAddressComponent(['postal_code']),
                city: getAddressComponent(['locality']),
                county: getAddressComponent(['administrative_area_level_1']),
            });
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    return (
        <Accordion sx={{ boxShadow: 'none', border: 'none' }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ backgroundColor: '#fff', borderRadius: '8px', padding: '0 16px' }}
            >
                <Typography variant="h6" component="h3" sx={{ fontWeight: 'bold', color: '#6a0dad' }}>Creare AWB</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fff', padding: '16px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <TextField
                        label="Strada"
                        variant="outlined"
                        fullWidth
                        name="street"
                        value={awbData.street || value}
                        onChange={(e) => {
                            handleInputChange(e);
                            setValue(e.target.value);
                        }}
                        onBlur={() => {
                            if (status === 'OK') {
                                handleSelect(value);
                            }
                        }}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label="Nr"
                                variant="outlined"
                                fullWidth
                                name="number"
                                value={awbData.number}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Cod postal"
                                variant="outlined"
                                fullWidth
                                name="postalCode"
                                value={awbData.postalCode}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label="Oras"
                                variant="outlined"
                                fullWidth
                                name="city"
                                value={awbData.city}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Judet"
                                variant="outlined"
                                fullWidth
                                name="county"
                                value={awbData.county}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        label="Observatii"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        name="observations"
                        value={awbData.observations}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Ridicare"
                        type="date"
                        value={pickupDate}
                        onChange={handleDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ maxWidth: 200 }}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: '#1E90FF', '&:hover': { backgroundColor: '#1C86EE' } }}
                            onClick={createAwb}
                        >
                            Creaza AWB
                        </Button>
                        <FormControlLabel
                            control={<Checkbox checked={schedule} onChange={() => setSchedule(!schedule)} />}
                            label="Programeaza"
                        />
                        {schedule && (
                            <TextField
                                label="Data"
                                type="date"
                                value={scheduleDate}
                                onChange={(e) => setScheduleDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{ maxWidth: 200 }}
                            />
                        )}
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default AwbAccordion;
