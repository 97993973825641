import React, { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppBar, Toolbar, List, ListItem, ListItemText, Container, Grid, CssBaseline, Typography, IconButton, Badge, Popover, Chip, ListItemIcon } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Page7 from './Page7';
import Page8 from './Page8';
import Page9 from './Page9';
import Page10 from './Page10';
import Page11 from './Page11';
import Page12 from './Page12';
import Page13 from './Page13';
import Page14 from './Page14';
import Page15 from './Page15';
import Page16 from './Page16';
import Page17 from './Page17';
import Page18 from './Page18';
import Page19 from './Page19';
import Page20 from './Page20';
import Page21 from './Page21';
import Login from './login';
import Monitoring from './Monitoring';
import styled from 'styled-components';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import BuildIcon from '@mui/icons-material/Build';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BookIcon from '@mui/icons-material/Book';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InventoryIcon from '@mui/icons-material/Inventory';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArticleIcon from '@mui/icons-material/Article';
import MenuBookIcon from '@mui/icons-material/MenuBook'; // Adăugat importul pentru Jurnale
import { AuthProvider, useAuth } from './AuthContext';
import AssessmentIcon from '@mui/icons-material/Assessment';

const ResponsiveGrid = styled(Grid)`
  @media (max-width: 600px) {
    & > .MuiGrid-item {
      flex-grow: 1;
      max-width: 100%;
    }
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    & > .MuiGrid-item {
      padding: 8px;
    }
  }
`;

const StyledDrawer = styled(MuiDrawer)`
  width: 240px;
  flex-shrink: 0;
  & .MuiDrawer-paper {
    width: 240px;
    margin-top: 64px;
  }

  @media (max-width: 600px) {
    & .MuiDrawer-paper {
      margin-top: 64px;
    }
  }
`;

const StyledAppBar = styled(MuiAppBar)`
  z-index: 1201;
`;

const StyledContainer = styled(Container)`
  padding-top: 64px;
  &.css-19r6kue-MuiContainer-root {
    margin-left: -100px !important;
    width: 1570px;
  }
`;

function App() {
    const [currentPage, setCurrentPage] = useState('Page 10');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [showFinanciar, setShowFinanciar] = useState(false);
    const [showAutoFacturi, setShowAutoFacturi] = useState(false);
    const [showLLP, setShowLLP] = useState(false);
    const [showDeconturi, setShowDeconturi] = useState(false);
    const [showAchiztii, setShowAchiztii] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        if (!isMobile) {
            setIsDrawerOpen(true);
        }
    }, [isMobile]);

    const handleDrawerToggle = (event) => {
        event.preventDefault();
        setIsDrawerOpen(!isDrawerOpen);
    };

    useEffect(() => {
        const savedPage = localStorage.getItem('currentPage');
        if (savedPage) {
            setCurrentPage(savedPage);
        }
    }, []);

    const handleNotificationClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleNotificationClose = () => {
        setAnchorEl(null);
    };

    const isNotificationOpen = Boolean(anchorEl);

    const TopBar = () => {
        const { setIsAuthenticated } = useAuth();
        const version = "v2.6.2.0";  // Aici poți actualiza versiunea aplicației       

        const handleLogout = () => {
            localStorage.removeItem('authToken');
            setIsAuthenticated(false);
        };

        return (
            <StyledAppBar position="fixed">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
                        BackOffice Luxura
                    </Typography>
                    <Chip label={`Version ${version}`} color="primary" style={{ marginLeft: '8px', backgroundColor: '#fff', color: '#3f51b5' }} />
                    <IconButton color="inherit" onClick={handleNotificationClick}>
                        <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <Popover
                        open={isNotificationOpen}
                        anchorEl={anchorEl}
                        onClose={handleNotificationClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Typography style={{ padding: '8px' }}>Notificările tale...</Typography>
                    </Popover>
                    <IconButton color="inherit" onClick={handleLogout}>
                        <ExitToAppIcon />
                    </IconButton>
                </Toolbar>
            </StyledAppBar>
        );
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        localStorage.setItem('currentPage', page);
    };

    const PageLayout = ({ children }) => (
        <ResponsiveGrid container>
            <ResponsiveGrid item xs={12} md={3}>
                <StyledDrawer
                    variant={isMobile ? "temporary" : "permanent"}
                    open={isDrawerOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{ keepMounted: true }}
                >
                    <List>
                        <ListItem button onClick={() => handlePageChange('Page 10')}>
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashbord Jira" />
                        </ListItem>
                        <ListItem button onClick={() => handlePageChange('Monitoring')}>
                            <ListItemIcon>
                                <RequestPageIcon />
                            </ListItemIcon>
                            <ListItemText primary="Management Cereri" />
                        </ListItem>
                        <ListItem button onClick={() => handlePageChange('Page 4')}>
                            <ListItemIcon>
                                <BuildIcon />
                            </ListItemIcon>
                            <ListItemText primary="Tests" />
                        </ListItem>
                        <ListItem button onClick={() => handlePageChange('Page 3')}>
                            <ListItemIcon>
                                <GroupWorkIcon />
                            </ListItemIcon>
                            <ListItemText primary="Run Scripts" />
                        </ListItem>
                        <ListItem button onClick={() => handlePageChange('Page 8')}>
                            <ListItemIcon>
                                <AccountBalanceIcon />
                            </ListItemIcon>
                            <ListItemText primary="Luxura Club" />
                        </ListItem>
                        <ListItem button onClick={() => setShowFinanciar(!showFinanciar)}>
                            <ListItemIcon>
                                <AttachMoneyIcon />
                            </ListItemIcon>
                            <ListItemText primary="Financiar" />
                            {showFinanciar ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItem>
                        {showFinanciar && (
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => handlePageChange('Page 20')}>
                                    <ListItemIcon>
                                        <AssessmentIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Cum stam?" />
                                </ListItem>
                                <ListItem button onClick={() => handlePageChange('Page 12')}>
                                    <ListItemIcon>
                                        <MenuBookIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Jurnale" />
                                </ListItem>
                                <ListItem button onClick={() => setShowAutoFacturi(!showAutoFacturi)}>
                                    <ListItemIcon>
                                        <ReceiptIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Autofacturi" />
                                    {showAutoFacturi ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </ListItem>
                                {showAutoFacturi && (
                                    <List component="div" disablePadding>
                                        <ListItem button onClick={() => handlePageChange('Page 11')}>
                                            <ListItemText primary="Crează Autofacturi" />
                                        </ListItem>
                                        <ListItem button onClick={() => handlePageChange('Page 12')}>
                                            <ListItemText primary="Vizualizare Autofacturi" />
                                        </ListItem>
                                    </List>
                                )}
                                <ListItem button onClick={() => setShowLLP(!showLLP)}>
                                    <ListItemIcon>
                                        <CreditCardIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="LLP" />
                                    {showLLP ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </ListItem>
                                {showLLP && (
                                    <List component="div" disablePadding>
                                        <ListItem button onClick={() => handlePageChange('Page 14')}>
                                            <ListItemText primary="Comenzi" />
                                        </ListItem>
                                        <ListItem button onClick={() => handlePageChange('Page 15')}>
                                            <ListItemText primary="Credite Active" />
                                        </ListItem>
                                    </List>
                                )}
                                <ListItem button onClick={() => setShowDeconturi(!showDeconturi)}>
                                    <ListItemIcon>
                                        <AccountBalanceIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Banci" />
                                    {showDeconturi ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </ListItem>
                                {showDeconturi && (
                                    <List component="div" disablePadding>
                                        <ListItem button onClick={() => handlePageChange('Page 16')}>
                                            <ListItemText primary="Vizualizează Deconturi" />
                                        </ListItem>
                                        <ListItem button onClick={() => handlePageChange('Page 17')}>
                                            <ListItemText primary="Încarcă Deconturi" />
                                        </ListItem>
                                    </List>
                                )}
                            </List>
                        )}
                        <ListItem button onClick={() => setShowAchiztii(!showAchiztii)}>
                            <ListItemIcon>
                                <ArticleIcon />
                            </ListItemIcon>
                            <ListItemText primary="ArticoleAI" />
                            {showAchiztii ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItem>
                        {showAchiztii && (
                            <List component="div" disablePadding>
                                <ListItem button onClick={() => handlePageChange('Page 2')}>
                                    <ListItemText primary="Master Data" />
                                </ListItem>
                                <ListItem button onClick={() => handlePageChange('Page 18')}>
                                    <ListItemText primary="Calculator Pret Intrare" />
                                </ListItem>
                                <ListItem button onClick={() => handlePageChange('Page 19')}>
                                    <ListItemText primary="ArticoleAI" />
                                </ListItem>
                                <ListItem button onClick={() => handlePageChange('Page 21')}>
                                    <ListItemText primary="Analiza Stocuri" />
                                </ListItem>
                            </List>
                        )}
                    </List>
                </StyledDrawer>
            </ResponsiveGrid>
            <ResponsiveGrid item xs={12} md={9}>
                {children}
            </ResponsiveGrid>
        </ResponsiveGrid>
    );

    const PrivateRoute = ({ children }) => {
        const { isAuthenticated } = useAuth();
        return isAuthenticated ? children : <Navigate to="/login" />;
    };

    return (
        <AuthProvider>
            <Router>
                <CssBaseline />
                <TopBar />
                <StyledContainer maxWidth="xl">
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/" element={
                            <PrivateRoute>
                                <PageLayout>
                                    {currentPage === 'Page 10' && <Page10 />}
                                    {currentPage === 'Page 2' && <Page2 />}
                                    {currentPage === 'Page 4' && <Page4 />}
                                    {currentPage === 'Page 3' && <Page3 />}
                                    {currentPage === 'Page 7' && <Page7 />}
                                    {currentPage === 'Page 8' && <Page8 />}
                                    {currentPage === 'Page 9' && <Page9 />}
                                    {currentPage === 'Page 11' && <Page11 />}
                                    {currentPage === 'Page 12' && <Page12 />}
                                    {currentPage === 'Page 13' && <Page13 />}
                                    {currentPage === 'Page 14' && <Page14 />}
                                    {currentPage === 'Page 15' && <Page15 />}
                                    {currentPage === 'Page 16' && <Page16 />}
                                    {currentPage === 'Page 17' && <Page17 />}
                                    {currentPage === 'Page 18' && <Page18 />}
                                    {currentPage === 'Page 19' && <Page19 />}
                                    {currentPage === 'Page 20' && <Page20 />}
                                    {currentPage === 'Page 21' && <Page21 />}
                                    {currentPage === 'Monitoring' && <Monitoring />}
                                </PageLayout>
                            </PrivateRoute>
                        } />
                    </Routes>
                </StyledContainer>
            </Router>
        </AuthProvider>
    );
}

export default App;
